var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper bg-light-info auth-v1 px-2"
  }, [_c('div', {
    staticClass: "auth-inner py-2"
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('b-img', {
    attrs: {
      "src": _vm.appLogoFull,
      "height": "70"
    }
  })], 1), _c('b-card-title', {
    staticClass: "mb-3 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('login.forgotPassword')) + " 🔒 ")]), _c('b-card-text', {
    staticClass: "mb-1 text-info text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('login.requiredText_1')) + " "), _c('em', {
    staticClass: "font-weight-bolder text-danger"
  }, [_vm._v(_vm._s(_vm.emailOTPSent))]), _vm._v(" " + _vm._s(_vm.$t('login.requiredText_1_2')) + " ")]), _c('b-card-text', {
    staticClass: "mb-3 text-info text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('login.requiredText_2')) + " "), _c('em', {
    staticClass: "font-weight-bolder text-danger"
  }, [_vm._v(_vm._s(_vm.emailOTPSent))]), _vm._v(" " + _vm._s(_vm.$t('login.requiredText_2_2')) + " ")]), _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          staticClass: "auth-forgot-password-form mt-2",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.validationForm($event);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": _vm.$t('login.otpCode'),
            "label-for": "otp-code"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "OTP",
            "rules": "required|alpha-num"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "otp-code",
                  "state": errors.length > 0 ? false : null,
                  "name": "otp-code",
                  "maxlength": "4",
                  "placeholder": _vm.$t('login.otpCodePlaceholder')
                },
                model: {
                  value: _vm.otpCode,
                  callback: function callback($$v) {
                    _vm.otpCode = $$v;
                  },
                  expression: "otpCode"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-button', {
          attrs: {
            "variant": "primary",
            "block": "",
            "type": "submit",
            "disabled": invalid
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('login.resetPassword')) + " ")])], 1)];
      }
    }])
  }), _c('b-card-text', {
    staticClass: "text-center mt-2"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'auth-login-v1'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ChevronLeftIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('login.backToLogin')) + " ")], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }